import React from 'react';
import { Mail, Linkedin, Server, Shield, GitBranch, Award, Cloud, Terminal, Activity, Book, ExternalLink } from 'lucide-react';

const ExpertiseCard = ({ icon, title, description }) => (
  <div className="p-6 bg-white rounded-lg shadow-md">
    <div className="mb-4">{icon}</div>
    <h4 className="text-xl font-semibold mb-2">{title}</h4>
    <p className="text-slate-600">{description}</p>
  </div>
);

const ImpactCard = ({ icon, title, description, link }) => (
  <div className="flex items-start space-x-4">
    <div className="mt-1">{icon}</div>
    <div>
      {link ? (
        <a 
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="group"
        >
          <h4 className="font-semibold mb-2 inline-flex items-center group-hover:text-blue-600">
            {title}
            <ExternalLink className="h-4 w-4 ml-1 opacity-0 group-hover:opacity-100 transition-opacity" />
          </h4>
        </a>
      ) : (
        <h4 className="font-semibold mb-2">{title}</h4>
      )}
      <p className="text-slate-600">{description}</p>
    </div>
  </div>
);

const App = () => {
  return (
    <div className="min-h-screen bg-slate-50">
      {/* Navigation */}
      <nav className="bg-slate-900 text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">SpookyFox</h1>
          <div className="flex items-center space-x-6">
            <a 
              href="https://www.linkedin.com/company/spookyfox/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 flex items-center"
            >
              <Linkedin className="h-5 w-5 mr-1" />
              <span className="hidden md:inline">Follow Us</span>
            </a>
            <a href="mailto:ballew@spookyfox.com" className="hover:text-blue-400">
              ballew@spookyfox.com
            </a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="bg-slate-800 text-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-4">Empowering Innovation Through DevOps Excellence</h2>
          <p className="text-xl text-slate-300">
            With 15+ years of experience in large-scale automation, we transform how companies deploy, secure, and operate their infrastructure. From UC Berkeley to Okta, we've helped organizations reduce deployment times from months to days.
          </p>
        </div>
      </header>

      {/* Expertise Section */}
      <section className="py-16 container mx-auto px-4">
        <h3 className="text-2xl font-bold mb-8">Our Expertise</h3>
        <div className="grid md:grid-cols-2 gap-8">
          <ExpertiseCard
            icon={<Cloud className="h-8 w-8 text-blue-600" />}
            title="Cloud Infrastructure"
            description="Expert implementation of AWS infrastructure using Terraform/OpenTofu, with proven success in migrating legacy systems to modern cloud architecture. Specialized in AWS, Azure, and bare metal deployments."
          />
          <ExpertiseCard
            icon={<Terminal className="h-8 w-8 text-blue-600" />}
            title="Configuration Management"
            description="Mastery of Ansible, Chef, and container orchestration (ECS/EKS). We've reduced environment setup times from days to minutes using automated playbooks and Docker compose systems."
          />
          <ExpertiseCard
            icon={<GitBranch className="h-8 w-8 text-blue-600" />}
            title="Advanced CI/CD"
            description="Implementation of robust pipelines using Github Actions, Jenkins, and TeamCity. Our CI/CD solutions have consistently cut development time in half while improving code quality."
          />
          <ExpertiseCard
            icon={<Activity className="h-8 w-8 text-blue-600" />}
            title="Monitoring Excellence"
            description="Comprehensive monitoring solutions using Splunk, ELK Stack, CloudTrail, and NewRelic. We implement tagged logging systems that transform troubleshooting from guesswork to precision."
          />
        </div>
      </section>

      {/* Impact Section */}
      <section className="bg-slate-100 py-16">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-bold mb-8">Recent Achievements</h3>
          <div className="grid md:grid-cols-2 gap-8">
            <ImpactCard
              icon={<Award className="h-6 w-6 text-green-600" />}
              title="UC Berkeley MyShake Project"
              description="Successfully migrated legacy click-ops AWS deployment to OpenTofu and Ansible, achieving a 99% reduction in deployment bugs and implementing comprehensive monitoring with NewRelic and Grafana."
              link="https://myshake.berkeley.edu"
            />
            <ImpactCard
              icon={<Shield className="h-6 w-6 text-green-600" />}
              title="Okta Security Enhancement"
              description="Led security DevOps initiatives that reduced audit findings by 90% and optimized Terraform deployment models, cutting build times from months to days."
            />
            <ImpactCard
              icon={<Server className="h-6 w-6 text-green-600" />}
              title="Enterprise Infrastructure"
              description="Developed custom Terraform templates using Atmos that enable rapid AWS infrastructure deployment, significantly reducing complexity and deployment timeframes."
            />
            <ImpactCard
              icon={<Book className="h-6 w-6 text-green-600" />}
              title="Community Leadership"
              description="Co-founder of Chatresearch, a 700-member non-profit supporting open source projects through grants and collaborative development."
              link="https://chatresearch.org"
            />
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <footer className="bg-slate-900 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <h3 className="text-xl font-bold mb-4">Connect at Web Summit</h3>
          <p className="mb-4">Meet our CEO Mark Ballew to discuss how we can transform your infrastructure</p>
          <div className="flex justify-center items-center space-x-6 mb-4">
            <a
              href="mailto:ballew@spookyfox.com"
              className="inline-flex items-center text-blue-400 hover:text-blue-300"
            >
              <Mail className="h-5 w-5 mr-2" />
              ballew@spookyfox.com
            </a>
            <a 
              href="https://www.linkedin.com/company/spookyfox/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-blue-400 hover:text-blue-300"
            >
              <Linkedin className="h-5 w-5 mr-2" />
              Follow Us on LinkedIn
            </a>
          </div>
          <p className="mt-2 text-slate-400">Falamos Português</p>
        </div>
      </footer>
    </div>
  );
};

export default App;